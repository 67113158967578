/*
 * @description: 
 * @Author: latiao
 * @Date: 2021-12-08 16:25:40
 * @LastEditors: latiao
 * @LastEditTime: 2022-03-01 09:32:17
 */
import api from "@/libs/request";

/** 查询所有文章标签*/
export const getAllArticleClassify = () => {
  return api.get(`/pc/articleClassify/getAllArticleClassify`, {});
};
/** pc查看推荐文章*/
export const getrecommendArticle = () => {
  return api.get(`/pc/article/recommendArticle`, {});
};
/** pc根据文章标签id查看文章列表*/
export const getArticleList = (data:any) => {
  return api.get(`/pc/article/getArticleList`, data);
};
/** 查看文章详情*/
export const getArticleDetail = (id:any) => {
  return api.get(`/pc/article/getArticleDetail/${id}`, {});
};
/** 
根据产品id查询产品下的文章*/
export const getproductArticle = (data:any) => {
  return api.get(`/pc/article/getArticle`, data);
};
/** 查询首页下的文章*/
export const getHomePageArticle = () => {
  return api.get(`/pc/article/getHomePageArticle`, {});
};
