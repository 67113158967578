













































import { Component, Vue } from "vue-property-decorator";
import RightInformation from "@/components/RightInformation.vue";
import { getsucceedCase } from "@/api/product";
@Component({
  components: { RightInformation },
})
export default class Case extends Vue {
  succeedCaselist = [];
  created() {
    getsucceedCase({ id: this.$route.query.key }).then((res) => {
      res.data.forEach((item: any) => {
        item.identification = true;
      });
      this.succeedCaselist = res.data;
    });
  }
}
