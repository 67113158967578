







































import { Vue, Component, Watch } from "vue-property-decorator";
import { getproductArticle } from "@/api/news";
@Component
export default class Footer extends Vue {
  Article = [];
  created() {
    getproductArticle({ productId: this.$route.query.key }).then((res) => {
      this.Article = res.data;
    });
  }
  @Watch("$route.query.key")
  getproductArticle() {
    getproductArticle({ productId: this.$route.query.key }).then((res) => {
      this.Article = res.data;
    });
  }
    Jump(id:any){
    this.$router.push({ path: "/newsdetails", query: { id } });
  }
  gotonews(){
    this.$router.push({ path: "/news" });
  }
  onChatClick() {
      (window as any).qimoChatClick();
    }
}
