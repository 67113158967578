/*
 * @description: 
 * @Author: latiao
 * @Date: 2021-12-08 16:25:40
 * @LastEditors: latiao
 * @LastEditTime: 2021-12-20 17:16:49
 */
import api from "@/libs/request";

/** 根据产品id查看产品相关轮播图*/
export const getproduct= (data:any) => {
  return api.get(`/pc/product/productImg`, data);
};
/** 根据产品id查看功能价格*/
export const getfunctionPrice= (data:any) => {
  return api.get(`/pc/product/functionPrice`, data);
};
/** 根据产品id查看成功案例*/
export const getsucceedCase= (data:any) => {
  return api.get(`/pc/product/succeedCase`, data);
};
/** 根据产品id查询套餐*/
export const getfunctionPriceList= (data:any) => {
  return api.get(`/pc/product/functionPriceList`, data);
};
/** 根据套餐id查询日志*/
export const getproductLogList= (data:any) => {
  return api.get(`/pc/product/productLogList`, data);
};

